<template>
  <b-card
    no-body
    style="height:115px"
  >
    <b-card-body class="d-flex justify-content-between align-items-center">
      <div class="truncate">
        <span v-if="!showDetail">
          <b-spinner
            variant="primary"
            type="grow"
            label="Spinning"
          />
        </span>
        <span v-else>
          <h2 class="mb-25 font-weight-bolder">
            {{ statistic }} <span
              style="font-size:14px; display: grid;"
              class="text-primary"
            >{{ statisticSub }}</span>
          </h2>
          <span>{{ statisticTitle }}</span><br>
          <span
            v-if="totalOverage"
            style="font-size:13px; display: grid;"
            class="text-primary"
          >{{ totalOverage }} <br></span>
          <span
            v-if="totalOverageCost"
            style="font-size:13px; display: grid;"
            class="text-primary"
          >{{ totalOverageCost }} <br></span>
        </span>
      </div>
      <b-avatar
        :variant="`light-${color}`"
        size="45"
      >
        <feather-icon
          size="21"
          :icon="icon"
        />
      </b-avatar>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BAvatar, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
    BSpinner,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    statisticSub: {
      type: [Number, String],
      required: false,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    totalOverage: {
      type: String,
      default: '',
    },
    totalOverageCost: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    showDetail: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const refreshId = setInterval(() => {
      if (this.showDetail) {
        clearInterval(refreshId)
      }
    }, 500)
  },
}
</script>
