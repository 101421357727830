<template>
  <admin-home v-if="user.userRole === 'Admin'" />
  <affiliate-home v-else-if="user.userRole === 'Affiliate'" />
  <user-home v-else />
</template>

<script>
import AdminHome from './home/AdminHome.vue'
import UserHome from './home/UserHome.vue'
import AffiliateHome from './home/AffiliateHome.vue'

export default {
  components: {
    AdminHome,
    UserHome,
    AffiliateHome,
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
  },
  mounted() {
    const calendlyMSG = this.getCookie('calendlyMSG')

    if (calendlyMSG && calendlyMSG?.length > 0) {
      this.notify({
        text: 'The session was programmed successfully !',
        variant: 'success',
      })
      this.expiredCookie('calendlyMSG', null)
    }
  },
  methods: {
    expiredCookie(cname, cvalue) {
      const expires = 'expires=Thu, 01 Jan 1970 00:00:00 UTC'
      document.cookie = `${cname}=${cvalue};${expires};path=/`
    },

    getCookie(cname) {
      const name = `${cname}=`
      const decodedCookie = decodeURIComponent(document.cookie)
      const ca = decodedCookie.split(';')
      for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    },
  },
}
</script>
