<template>
  <b-card no-body>
    <b-card-body>
      <div class="truncate">
        <div class="row">
          <div class="col-9 col-sm-10">
            <div class="row">
              <div :class="custom_recurrent >= 0 ?`col-sm-6`:`col-sm-12`">
                <span class="text-primary">{{ statisticTitle }}</span>
                <h2
                  class="font-weight-bolder text-primary"
                >
                  {{ statistic }}
                </h2>
                <span class="text-primary">{{ averageTitle }}</span>
              </div>
              <div
                v-if="custom_recurrent >= 0 "
                class="col-sm-6"
              >
                <span class="text-primary">Custom Recurrent Services:
                  <b
                    style="cursor: pointer"
                    @click="recurrent"
                  >€{{ custom_recurrent }}
                  </b>
                </span>
                <br>
                <span class="text-primary">Custom Intervention:
                  <b
                    style="cursor: pointer"
                    @click="intervention"
                  >
                    €{{ custom_intervention }}
                  </b>
                </span>
              </div>
            </div>
          </div>
          <div class="col-3 col-sm-2">
            <b-avatar
              class="float-right"
              :variant="`light-${color}`"
              size="45"
            >
              <feather-icon
                size="21"
                :icon="icon"
              />
            </b-avatar>
          </div>
        </div>
      </div>
    </b-card-body>

    <router-link :to="url">
      <vue-apex-charts
        type="area"
        height="100"
        width="100%"
        :options="chartOptionsComputed"
        :series="chartData"
      />
    </router-link>

    <custom-recurrent
      v-if="custom_recurrent >= 0 "
      :statistic-title="statisticTitle"
      :is-paid="isPaid1"
      :months="timeFrame"
    />

    <custom-intervention
      v-if="custom_recurrent >= 0 "
      :statistic-title="statisticTitle"
      :is-paid="isPaid1"
      :months="timeFrame"
    />

  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BAvatar,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import CustomRecurrent from '@core/components/statistics-cards/CustomRecurrent.vue'
import CustomIntervention from '@core/components/statistics-cards/CustomIntervention.vue'
import { lineChartOptions } from './chartOptions'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardBody,
    BAvatar,
    CustomRecurrent,
    CustomIntervention,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    timeFrame: {
      type: String,
      required: true,
    },
    custom_recurrent: {
      type: Number,
      required: false,
    },
    custom_intervention: {
      type: Number,
      required: false,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    averageTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    chartOptions: {
      type: Object,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    isPaid1: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    chartOptionsComputed() {
      if (this.chartOptions === null) {
        const options = JSON.parse(JSON.stringify(lineChartOptions))

        options.fill.gradient.gradientToColors = [this.gradientToColor(this.color)]
        options.colors = [$themeColors[this.color]]

        return options
      }
      return this.chartOptions
    },
  },
  created() {
  },
  methods: {
    gradientToColor(color) {
      const gradientToColors = {
        primary: '#A9A2F6',
        success: '#55DD92',
        warning: '#ffc085',
        danger: '#F97794',
        info: '#59E0F0',
        secondary: '#B4B9BF',
        light: '#D0D4DB',
        dark: '#919191',
      }

      return gradientToColors[color]
    },
    recurrent() {
      this.$bvModal.show(`${this.$props.statisticTitle}_recurrent_modal`)
    },
    intervention() {
      this.$bvModal.show(`${this.$props.statisticTitle}_intervention_modal`)
    },
  },
}
</script>
<style lang="scss" scoped>
.dark-layout .text-primary {
  color: #e864a2 !important;
}
</style>
