<template>
  <div>
    <!-- <div v-if="!customer.hasSigned">
      <b-modal
        id="save-dpa-agreement"
        hide-footer
        centered
        size="xl"
        no-close-on-backdrop
        hide-header-close
        no-close-on-esc
        title="Please accept the DPA Agreement."
      >
        <div class="text-center">
          <DpaAgreementVue
            :customer="customer"
            @generatedPDF="checkGeneratedPDF"
            @userAgreeToDPA="setDPAAgreement"
          />
          <div>
            <b-alert
              show
              :variant="(!agreeDPA)?'warning':'success'"
            >
              <span v-if="agreeDPA">The DPA has been successfully signed.</span>
              <span v-else>Please accept and sign the DPA Agreement in order to use the platform !</span>
            </b-alert>
          </div>
          <b-button
            v-if="agreeDPA"
            style="margin:5px 0px 0px 3px; padding: 11px 39px;"
            variant="primary"
            @click="cancel_save_dpa"
          >Close</b-button>
        </div>
      </b-modal>
      <b-alert
        show
        variant="danger"
        class="alert-m"
      >
        <h4 class="alert-heading">
          <feather-icon
            icon="InfoIcon"
            size="18"
          /> Very Important
        </h4>
        <div
          class="text-primary alert-div-p"
        >
          Please accept and sign the DPA Agreement in order to use the platform !
          <b-button
            size="sm"
            class="arlert-button"
            variant="danger"
            @click="openDPA"
          >
            <feather-icon
              icon="FlagIcon"
              size="12"
            />
            Sign the DPA Agreement</b-button>
        </div>
      </b-alert>
    </div> -->
    <div v-if="isShowAlert || isUpgrade">
      <div v-if="hasNewsAlert">
        <b-alert
          v-if="alertNews.news"
          show
          style="background-color:#d4f4e2 !important; box-shadow: #f6f3c3 0px 6px 15px -7px !important;"
        >
          <h4
            class="alert-heading"
            style="color: #c43c8c !important; display: flex;align-items: center;gap:10px;"
          >
            <feather-icon
              icon="InfoIcon"
              variant="primary"
              size="18"
            />{{ alertNews.news.tag }}
          </h4>
          <div
            class="text-primary alert-div-p"
          >
            {{ alertNews.news.title }}
            <b-button
              size="sm"
              variant="primary"
              class="arlert-button"
              @click="goToNewsAlert(alertNews.news.id, alertNews.news.tag)"
            >
              <feather-icon
                icon="BellIcon"
                size="12"
              />
              Read more...
            </b-button>
          </div>
        </b-alert>
        <b-alert
          v-if="alertNews.product_updates"
          show
          variant="info"
        >
          <h4
            class="alert-heading"
          >
            <feather-icon
              icon="InfoIcon"
              variant="primary"
              size="18"
            />{{ alertNews.product_updates.tag }}
          </h4>
          <div
            class="alert-div-p"
          >
            {{ alertNews.product_updates.title }}
            <b-button
              size="sm"
              variant="info"
              class="arlert-button"
              @click="goToNewsAlert(alertNews.product_updates.id, alertNews.product_updates.tag)"
            >
              <feather-icon
                icon="BellIcon"
                size="12"
              />
              Read more...
            </b-button>
          </div>
        </b-alert>
      </div>
      <b-alert
        v-if="flag >= 1 && flag <= 5"
        show
        dismissible
        variant="info"
        class="alert-m"
      >
        <h4
          v-if="flag !== 10"
          class="alert-heading"
        >
          <feather-icon
            icon="InfoIcon"
            size="18"
          /> Notice
        </h4>
        <div
          v-if="flag===1"
          class="alert-div-p"
        >
          Your 30-day trial has <b>NOT STARTED.</b> We are validating your configuration.
        </div>
        <div
          v-if="flag===2"
          class="alert-div-p"
        >
          Your 30-day trial has <b>NOT STARTED.</b>

          <div
            size="sm"
          >
            <feather-icon
              icon="FlagIcon"
              size="12"
            />
            Waiting for the nameservers setup.
          </div>

        </div>
        <div
          v-if="flag===3"
          class="alert-div-p"
        >
          Your 30-day trial has <b>NOT STARTED.</b>
          <b-button
            class="arlert-button"
            size="sm"
            variant="success"
            @click="goToConfiguration()"
          >
            <feather-icon
              icon="ToolIcon"
              size="12"
            />
            Please finish your system's configuration
          </b-button>
        </div>
        <div
          v-if="flag===4"
          class="alert-div-p"
        >
          Your 30-day trial <b>HAS ENDED.</b>
          <b-button
            class="arlert-button"
            size="sm"
            variant="success"
            @click="goToAccount()"
          >
            <feather-icon
              icon="UserIcon"
              size="12"
            />
            See your account in details.
          </b-button>
        </div>
        <div
          v-if="flag===5"
          class="alert-div-p"
        >
          Your 30-day trial will <b>END</b> in <b>{{ trial }} DAYS</b>.
          <br>
          To continue utilizing our services, please ensure that your pro forma invoice has been paid prior to the conclusion of the trial period. You can access your invoice information by clicking <router-link :to="`/invoice/current`">
            HERE
          </router-link>.
        </div>
        <!-- <div
          v-if="flag===6"
          style="padding:30px"
        >
          Your pro forma invoice # <b>{{ invoice.credit_note }} </b> is <b>DUE/OVERDUE</b>
          <b-button
            style="float:right; margin-bottom: 5px;"
            size="sm"
            variant="warning"
            @click="goToInvoice(invoice.id)"
          >
            <feather-icon
              icon="ClockIcon"
              size="12"
            />
            See pro forma Invoice # {{ invoice.credit_note }} in details.
          </b-button>
        </div> -->
      </b-alert>
      <b-alert
        v-if="invoice_ids.length > 0"
        show
        dismissible
        variant="primary"
        style="background-color:#f6f3c3 !important; box-shadow: #f6f3c3 0px 6px 15px -7px !important;"
        class="alert-m"
      >
        <h4
          v-if="flag !== 10"
          class="alert-heading"
          style="box-shadow: #f5ee80 0px 6px 15px -7px !important; color: #c43c8c !important; display: flex;align-items: center;gap:10px;"
        >
          <feather-icon
            icon="InfoIcon"
            variant="primary"
            size="18"
          /> Notice
        </h4>
        <div
          v-if="invoice_ids.length > 0"
          class="text-primary alert-div-p"
        >

          <div
            v-for="(invoice, index) in invoice_ids"
            :key="invoice.id"
            style="width:100%"
          >
            Your pro forma invoice # <b>{{ invoice.credit_note }} </b> is <b>DUE/OVERDUE</b>
            <b-button
              class="arlert-button"
              size="sm"
              variant="primary"
              @click="goToInvoice(invoice.id)"
            >
              <feather-icon
                icon="ShoppingCartIcon"
                size="12"
              />
              See pro forma Invoice # {{ invoice.credit_note }} in details.
            </b-button>
            <hr v-if="invoice_ids.length > 1 && invoice_ids.length-1 != index">
          </div>

          <!-- See the <router-link to="/invoice/current">
            Pro Forma Invoice
          </router-link>. -->
        </div>
      </b-alert>
      <b-alert
        v-if="userData.user_status === 8"
        show
        dismissible
        variant="success"
        class="alert-m"
      >
        <h4 class="alert-heading">
          <feather-icon
            icon="InfoIcon"
            size="18"
          /> Notice
        </h4>
        <div class="alert-div-p">
          <div style="display:flex;align-items: center;gap:10px;">
            <p>Your current platform status is BACKUP untill {{ backup_end }}. To reactivate your platform </p>
            <b-button
              class="btn btn-sm btn-success"
              @click="reActivatePlatform"
            >
              click here
            </b-button>
          </div>
        </div>
      </b-alert>
      <b-alert
        v-if="!userData.country"
        show
        dismissible
        variant="danger"
        class="alert-m"
      >
        <h4 class="alert-heading">
          <feather-icon
            icon="InfoIcon"
            size="18"
          /> Account Incomplete
        </h4>
        <div class="alert-div-p">
          <div style="display:flex;align-items: center;gap:10px;">
            <p>Please complete your account to get full access to the dashboard. </p>
            <b-button
              class="btn btn-sm btn-success"
              @click="goToMyAccount"
            >
              click here
            </b-button>
          </div>
        </div>
      </b-alert>
      <b-alert
        v-if="userData.user_status === 7"
        show
        dismissible
        variant="warning"
        class="alert-m"
      >
        <h4 class="alert-heading">
          <feather-icon
            icon="InfoIcon"
            size="18"
          /> Expired Notice (Optional)
        </h4>
        <div class="alert-div-p">
          <div style="display:flex; align-items:center;gap:25px;">
            <p style="">
              Your TrafficManager subscription has EXPIRED. If you want to continue using the platform, please,  pay your last pro forma invoice. Alternatively, you can suspend your subscription and create a warm BACKUP of your platform data that you can re-activate whenever you want.
            </p>
            <b-button
              v-b-modal.backup-select
              class="btn btn-warning"
            >
              Activate Backup
            </b-button>
          </div>
        </div>
      </b-alert>
      <b-modal
        id="new-packages-modal"
        cancel-variant="outline-secondary"
        hide-footer
        centered
        title="New Packages"
        size="lg"
      >
        <new-packages :u-details="userDetail" />
      </b-modal>
    </div>
    <!-- MODAL -->
    <b-modal
      id="backup-select"
      ref="modal"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Activate"
      centered
      title="Select billing cycle for backup"
      no-close-on-backdrop
      @ok="handleOk"
    >
      <b-form ref="form">
        <b-form-group>
          <b-form-select
            v-model="selectedCycle"
            @change="onChangeBillingCycle()"
          >
            <option
              v-for="option in options"
              :key="option.id"
              :value="option.id"
            >
              {{ option.title }}
            </option>
          </b-form-select>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BAlert, BButton, BForm, BFormSelect, BFormGroup,
} from 'bootstrap-vue'
import axios from '@/libs/axios' // eslint-disable-line
import NewPackage from './NewPackage.vue'
// import DpaAgreementVue from '../user_dpa_agreements/UserDpaAgreement.vue'

export default {
  components: {
    BAlert,
    BButton,
    BForm,
    BFormSelect,
    BFormGroup,
    'new-packages': NewPackage,
    // DpaAgreementVue,
  },
  props: {
    userDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isShowAlert: false,
      options: [
        {
          id: 5,
          title: 'Monthly',
        },
        {
          id: 6,
          title: 'Every 3 Months',
        },
      ],
      selectedCycle: '',
      alertText: '',
      flag: 0,
      trial: '',
      backup_end: '',
      invoice_due: 0,
      invoice_ids: [],
      isUpgrade: false,
      hasNewsAlert: false,
      alertNews: null,
      customer: {
        uid: 0,
        name: 'Customer Name',
        company: 'Customer Company',
        title: 'Customer Title',
        position: 'Customer Position',
        address: 'Customer Address',
        signature: '',
        hasSigned: true,
        dateSigned: '',
      },
      agreeDPA: false,
    }
  },
  computed: {
    userData() {
      return this.$store.state.auth.userData
    },
    isPhone: {
      get() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) return true
        return false
      },
    },
  },
  created() {
    // this.fillCustomerInfo()
    if (this.userData.user_status === 7) {
      this.selectedCycle = 5
    }
  },
  mounted() {
    const refreshId = setInterval(() => {
      if (this.userDetail) {
        this.intData()
        clearInterval(refreshId)
      }
    }, 500)
    if (this.userData.user_status === 8) {
      this.getUserBackup()
    }
  },
  methods: {
    goToMyAccount() {
      this.$router.push('/account')
    },
    reActivatePlatform() {
      const payload = {
        uid: this.userData.uid,
      }
      const payload2 = {
        uid: this.userData.uid,
        feature_id: 5,
        feature_id2: 6,
      }
      this.confirmationAlert('The system will generate a pro forma invoice. After paying, your status will update to subscribed and your platform will be re-activated. Continue ?')
        .then(async result => {
          if (result.isConfirmed) {
            const res = await axios.post('api/billing/generate-renew-pfi', payload)
            if (res.status === 200) {
              await axios.post('api/optional_feature/delete_feature', payload2)
              localStorage.setItem('invoice_message', res.data.message)
              this.$router.push(`invoice/current/${res.data.invoice_id}`)
            }
          }
        })
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.activateBackup(this.selectedCycle)
    },
    onChangeBillingCycle() {
      // console.log(this.selectedCycle)
    },
    async activateBackup(feature_id) {
      const payload = {
        feature_id,
      }
      this.confirmationAlert('To activate the backup, a pro forma invoice will be generated, and after the payment, the backup will be activated. Do you want to continue?')
        .then(async result => {
          if (result.isConfirmed) {
            const res = await axios.post('api/optional_feature/activate_backup', payload)
            if (res.status === 200) {
              if (res.data.denied) {
                this.$bvToast.toast(res.data.message, {
                  title: 'Error',
                  variant: 'danger',
                  solid: true,
                })
                return
              }
              localStorage.setItem('invoice_message', res.data.message)
              this.$router.push(`/invoice/current/${res.data.invoice_id}`)
            } else {
              this.$bvToast.toast('Something went wrong, please try again later!', {
                title: 'Error',
                variant: 'danger',
                solid: true,
              })
            }
          }
        })
    },
    getUserBackup() {
      axios.get('api/users/user_backup').then(response => {
        if (response.status === 200) {
          this.backup_end = response.data
        } else {
          this.backup_end = ''
        }
        // if resoonse is undefined, then backup_end is empty
        if (!response) {
          this.backup_end = ''
        }
      })
    },
    /* eslint-disable */
    // openDPA() {
    //   this.$bvModal.show('save-dpa-agreement')
    // },

    // async fillCustomerInfo()
    // {
    //   const checkEmptyData = (data) => {
    //       return (data?.length < 2 || !data || data == " ") ? ('N/A') : (data);
    //   };

    //   const user = this.$store.state.auth.userData
    //   this.customer.uid = user.uid

    //   const response = await axios.get(`/api/dpa/get_last_signed_dpa/${user.uid}`)

    //   if(response.data.signed === true) {
    //     const info = response.data.info
    //     this.customer.name = info.full_name
    //     this.customer.company = info.company
    //     this.customer.title = info.title
    //     this.customer.address = info.address
    //     this.customer.signature = info.signature
    //     this.customer.dateSigned = info.created_at
    //     this.customer.hasSigned = true
    //     this.agreeDPA = true
    //   } else {
    //     this.customer.name = `${user.forename} ${user.surname}`;
    //     this.customer.company = checkEmptyData(user.company);
    //     this.customer.title = checkEmptyData(user.title);
    //     this.customer.address = `${user.address1} ${user.city}, ${user.region} ${user.country}`;
    //     this.customer.hasSigned = false

    //     if(this.$store.state.auth.impersonate == false) {
    //       setTimeout(() => {
    //         this.$bvModal.show('save-dpa-agreement')
    //       }, 1500)
    //     }
    //   }

    //   if (this.customer.company != user.company) {
    //     this.customer.name = `${user.forename} ${user.surname}`;
    //     this.customer.company = checkEmptyData(user.company);
    //     this.customer.title = checkEmptyData(user.title);
    //     this.customer.address = `${user.address1} ${user.city}, ${user.region} ${user.country}`;
    //     this.customer.hasSigned = false
    //     this.agreeDPA = false
    //   }
    // },

    // cancel_save_dpa() {
    //   if(!this.agreeDPA) {
    //     this.notify({
    //       text: 'Please make sure you sign the DPA and fill out the form completely. Thank you!',
    //       variant: 'danger',
    //     });
    //     return;
    //   }
    //   const dpaModal = document.getElementById('save-dpa-agreement');
    //   dpaModal.style.visibility = "hidden";
    //   dpaModal.style.opacity = "0";
    //   document.body.style.overflow = "visible";

    //   const dpaBackdrop = document.getElementById('save-dpa-agreement___BV_modal_backdrop_');
    //   dpaBackdrop.remove();

    //   this.customer.hasSigned = true;
    // },

    // setDPAAgreement(value) {
    //   this.agreeDPA = value;
    // },

    // checkGeneratedPDF(value) {
    //   if (value) this.agreeDPA = true;
    //   else {
    //     this.$bvToast.toast('The PDF could not be generated successfully! Please create a ticket !', {
    //       title: 'PDF Generated',
    //       variant: 'danger',
    //       solid: true,
    //       autoHideDelay: 10000,
    //     });
    //   }
    // },

    intData() {
      if (this.userDetail.off_new_package_upgrade) {
        const packageNumber = Number(this.userDetail.billing.package)

        // const date1 = new Date('Tue Aug 16 2022 11:17:28 GMT+0800')
        // date1.setMonth(8)

        // const date2 = new Date()

        // let promoPeriod = false
        // if (date1.getTime() > date2.getTime()) promoPeriod = true

        let isPromoPackage = false
        if (packageNumber >= 7003 && packageNumber <= 7006) isPromoPackage = true

        // casino user with the current upgarde seem included in the promo
        // if (packageNumber === 5003 || packageNumber === 6003) isPromoPackage = true

        if (isPromoPackage === false) this.isUpgrade = true

        const newsAlert = this.getUserNewsAlert()
        if (newsAlert) {
          this.hasNewsAlert = true
          this.alertNews = newsAlert
        }
      }

      const setting = this.userDetail.settings
      const { user_status } = this.userDetail
      const { trial } = this.userDetail
      const { invoice_due } = this.userDetail
      const user = this.userData

      this.trial = trial
      this.invoice_due = invoice_due

      this.invoice_ids = this.userDetail.invoice_due_ids

      if (user.userRole === 'Affiliate' || user_status === 6) return

      if (Number(user_status) !== 5
        && !trial) {
        if (Number(setting.setstep1) === 1) {
          if (setting.network_type === 'domain' && setting.dns_type === 'owndns') this.flag = 1
          else this.flag = 2
        } else {
          this.flag = 3
        }
      } else if (Number(user_status) !== 5 && trial) {
        if (trial === 'ended' && Number(user_status) !== 8){
          this.flag = 4
        } else if (Number(user_status) === 8) {
          this.flag = 10
        } else if (Number(user_status)=== 1) {
          this.flag = 11
        }
        else this.flag = 5
      }

      // if (Number(invoice_due) === 1) {
      //   this.flag = 6
      //   const invoice_one = this.userDetail.invoice_due_ids
      //   this.invoice_ids = invoice_one[0] // eslint-disable-line
      //   console.log(this.invoice_ids)
      // }

      if (Number(invoice_due) >= 1 && setting.auto_payment_mode === 0 && this.flag !== 5) {
        this.flag = 7
        this.invoice_ids = this.userDetail.invoice_due_ids
      } else if (Number(invoice_due) >= 1 && this.flag !== 5) {
        this.flag = 0
      }

      if (this.flag > 0) this.isShowAlert = true
    },
    goToInvoice(id) {
      const uri = `/invoice/current/${id}`
      this.$router.push(uri)
    },
    goToAccount() {
      const uri = '/account'
      this.$router.push(uri)
    },
    goToConfiguration() {
      const uri = '/configuration'
      this.$router.push(uri)
    },
    getUserNewsAlert() {
      const news_alert = this.userDetail.user_news_alert
      return news_alert
    },
    goToNewsAlert(id, tag) {
      // if the tag includes newsletter
      if (tag === 'Newsletter') {
        const uri = `/news/user-detail/${id}`
        this.$router.push(uri)
      }
      else if (tag === 'Product Updates') {
        const uri = `/product-updates/user-detail/${id}`
        this.$router.push(uri)
      }
    },
  },
}
</script>

<style lang="scss">
.modal .modal-header .close{
  margin: 2px
}

/* if screen is more than 600px*/
@media only screen and (min-width: 600px)  {
  .alert-m{
    margin-bottom:20px
  }
  .alert-div-p{
    padding:30px
  }
  .arlert-button{
    float:right; margin-bottom: 5px;
  }
}

/* iphone 12/13 pro max - android pro max*/
@media only screen and (max-width: 428px)  {
  .alert-div-p{
    padding:30px;
    text-align: center;
  }
  .arlert-button{
    margin-top: 10px;
    background-color: #37b172 !important;
    width: 100% !important;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 415px)  {
  .alert-div-p{
    padding:30px;
    text-align: center;
  }
  .arlert-button{
    margin-top: 10px;
    background-color: #37b172 !important;
    width: 100% !important;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 395px)  {
  .alert-div-p{
    padding:30px;
    text-align: center;
  }
  .arlert-button{
    margin-top: 10px;
    background-color: #37b172 !important;
    width: 100% !important;
  }
}

/* smaller phone */
@media only screen and (max-width: 365px)  {
  .alert-div-p{
    padding:30px;
    text-align: center;
  }
  .arlert-button{
    margin-top: 10px;
    background-color: #37b172 !important;
    width: 100% !important;
  }
}

</style>
