<template>
  <b-modal
    :id="statisticTitle + `_intervention_modal`"
    cancel-variant="outline-secondary"
    hide-footer
    centered
    size="xl"
    title="Custom Intervention"
  >
    <div
      class="custom-search d-flex justify-content-end"
    >
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block closed-box2"
          />
        </div>
      </b-form-group>
    </div>
    <div class="text-center">
      <vue-good-table
        mode="remote"
        :is-loading.sync="isLoading"
        :columns="columns"
        :rows="rows"
        :sort-options="{
          enabled: true,
        }"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-search="onSearch"
      >
        <template slot="loadingContent">
          <div
            class="my-1 d-flex spinner-border text-primary justify-content-center"
            style="width: 2.5rem; height: 2.5rem; margin: auto;"
            role="status"
          />
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >

          <span v-if="props.column.field === 'invoice_number'">
            <router-link :to="`/invoices/`+props.row.invoice_id">
              {{ props.row.invoice_number }}
            </router-link>
          </span>

          <span v-if="props.column.field === 'item'">
            {{ props.row.item }}
          </span>

          <span v-if="props.column.field === 'concat_domain'">
            <router-link :to="`/user/details/`+props.row.uid">
              {{ props.row.concat_domain }}
            </router-link>
          </span>

          <span v-if="props.column.field === 'issue_date'">
            {{ formatDate(props.row.issue_date) }}
          </span>

          <span v-if="props.column.field === 'item_price'">
            € {{ parseFloat(props.row.item_price).toFixed(2) }}
          </span>

          <span v-if="props.column.field === 'invoice_status'">
            <b-badge :variant="statusVariant(getInvoiceStatus(props.row.invoice_status))">
              {{ getInvoiceStatus(props.row.invoice_status) }}
            </b-badge>
          </span>

        </template>
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap mt-1">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','25','50']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, BPagination, BFormSelect, BFormGroup, BFormInput, BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment/moment'

export default {
  components: {
    BBadge,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BPagination,
    BModal,
    VueGoodTable,
  },
  props: {
    statisticTitle: {
      type: String,
      default: '',
    },
    isPaid: {
      type: Boolean,
      default: null,
    },
    months: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pageLength: 10,
      searchTerm: '',
      totalRecords: 0,
      isLoading: false,
      rows: [],
      columns: [
        {
          label: 'id',
          field: 'id',
          hidden: true,
        },
        {
          label: 'Invoice #',
          field: 'invoice_number',
        },
        {
          label: 'Package',
          field: 'item',
        },
        {
          label: 'Domain Name',
          field: 'concat_domain',
        },
        {
          label: 'Issue Date',
          field: 'issue_date',
        },
        {
          label: 'Amount',
          field: 'item_price',
        },
        {
          label: 'Status',
          field: 'invoice_status',
        },
      ],
      serverParams: {
        search: '',
        isPaid: '',
        months: '',
        sort: [
          {
            field: 'id',
            type: 'desc',
          },
        ],
        page: 1,
        perPage: 10,
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Paid : 'light-success',
        Overdue     : 'light-danger',
        Pending      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  watch: {
    months(newValue) {
      this.serverParams.months = newValue
      this.loadItems()
    },
  },
  created() {
    this.serverParams.isPaid = this.$props.isPaid
    this.serverParams.months = this.$props.months
    this.loadItems()
  },
  methods: {
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    getInvoiceStatus(status) {
      if (status === 'pending') return 'Pending'
      if (status === 'overdue') return 'Overdue'
      return 'Paid'
    },

    // vue good table
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ page: 1, perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    onSearch() {
      this.updateParams({ page: 1, perPage: this.serverParams.perPage, search: this.searchTerm })
      this.loadItems()
    },
    // load items is what brings back the rows from server
    loadItems() {
      this.isLoading = true
      this.$store.dispatch('invoice/fetchCustomInterventionSSR', this.serverParams)
        .then(response => {
          this.totalRecords = response.total
          this.rows = response.data
          this.isLoading = false
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.dark-layout a {
  color: #fb50a1 !important;
}
</style>
