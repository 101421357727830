<!-- eslint-disable -->
<template>
  <span>
    <user-alert v-if="showDetail" :user-detail="userDetail" />
    <b-card no-body>
      <b-card-header>
        <!-- title and subtitle -->
        <div>
          <b-card-title class="mb-1">
            Setup
          </b-card-title>
          <b-card-sub-title>Current Process Stage</b-card-sub-title>
        </div>
        <!--/ title and subtitle -->
      </b-card-header>

      <b-card-body>
        <div v-if="!showDetail" class="mw-80 mx-auto step-progress">
          <div class="progress">
            <div class="progress-bar" role="progressbar" :aria-valuenow="initPercentage" aria-valuemin="0"
              aria-valuemax="100" :style="progressBar">
              <span class="sr-only">{{ initPercentage }}% Complete</span>
            </div>
          </div>
        </div>
        <div v-if="showDetail" class="mw-80 mx-auto step-progress">
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
              :style="progressBar">
              <span class="sr-only">70% Complete</span>
            </div>
          </div>
          <div class="d-flex justify-content-between position-relative zindex-1">
            <div class="text-center">
              <div class="step-icon-wrapper">
                <feather-icon icon="KeyIcon" size="35" class="step-icon" />
              </div>
              <h3>Registration</h3>
              <h5>Successfully registered</h5>
            </div>

            <div v-if="isConfigOk" class="text-center">
              <div class="step-icon-wrapper goToConfig" @click="goToConfigSetting">
                <feather-icon icon="TerminalIcon" size="35" class="step-icon goToConfig" />
              </div>
              <h3>Configuration</h3>
              <h5>System configured</h5>
            </div>
            <div v-else class="text-center">
              <div class="step-icon-wrapper goToConfig inactive" @click="goToConfigSetting">
                <feather-icon icon="TerminalIcon" size="35" />
              </div>
              <h3 class="inactive-text">Configuration</h3>
              <h5 class="inactive-text">Please, configure your system!</h5>
            </div>


            <div v-if="paidSetupFee" class="text-center">
              <div class="step-icon-wrapper goToConfig">
                <feather-icon icon="DollarSignIcon" size="35" class="step-icon goToConfig" />
              </div>
              <h3>Setup Fee</h3>
              <h5>Setup Fee Paid</h5>
            </div>
            <div v-else class="text-center">
              <div class="step-icon-wrapper goToConfig inactive" @click="goToSetupFee">
                <feather-icon icon="DollarSignIcon" size="35" />
              </div>
              <h3 class="inactive-text">Setup Fee</h3>
              <h5 class="inactive-text">Awaiting Setup Fee Payment</h5>
            </div>

            <div v-if="devShow === 1" class="text-center">
              <div class="step-icon-wrapper">
                <feather-icon icon="SendIcon" size="35" class="step-icon deploy" />
              </div>
              <h3>
                Deployment
              </h3>
              <h5>
                System is LIVE!
              </h5>
            </div>
            <div v-else-if="devShow === 2" class="text-center">
              <div class="step-icon-wrapper inactive">
                <feather-icon icon="SendIcon" size="35" class="step-icon deploy" />
              </div>
              <h3 class="inactive-text">
                Deployment
              </h3>
              <h5 class="inactive-text">
                {{ devShowText }}
              </h5>
            </div>
            <div v-else class="text-center">
              <div class="step-icon-wrapper inactive">
                <feather-icon icon="SendIcon" size="35" class="step-icon deploy" />
              </div>
              <h3 class="inactive-text">
                Deployment
              </h3>
              <h5 class="inactive-text">
                Awaiting your configuration
              </h5>
            </div>

          </div>
        </div>
      </b-card-body>
    </b-card>
  </span>

</template>

<script>
/* eslint-disable */
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import UserAlertVue from './UserAlert.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    'user-alert': UserAlertVue,
  },
  props: {
    info: {
      type: Object,
      default: null,
    },
    user_status: {
      type: Number,
      default: 1,
    },
    settings: {
      type: Object,
      default: null,
    },
    showDetail: {
      type: Boolean,
      default: false,
    },
    paidSetupFee: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initPercentage: 0,
      initPercentageText: 'width:0%',
      isConfigOk: false,
      devShow: 1,
      devShowText: '',
      progressBar: 'width:0%',
      userSetting: {},
      userDetail: {},
    }
  },
  mounted() {
    const refreshId = setInterval(() => {
      this.initPercentage += 1
      this.initPercentageText = `width:${this.initPercentage}%`
      if (this.showDetail) {
        this.initSetup()
        clearInterval(refreshId)
      }
    }, 500)
  },
  methods: {
    initSetup() {
      this.userDetail = this.info;
      this.userSetting = this.settings;
      const step = Number(this.settings.setstep1);
      const uStatus = Number(this.user_status);

      // Start with 25% for the first step (Registration)
      this.progressBar = 'width:25%';

      if (step === 1) {
        this.isConfigOk = true;
        this.progressBar = 'width:50%'; // 50% after Configuration is complete
      }

      // Check if the Setup Fee is paid
      if (this.paidSetupFee) {
        this.progressBar = 'width:85%'; // 75% after Setup Fee is paid
      } else if (step === 1) {
        this.progressBar = 'width:50%'; // Stay at 50% if fee is not paid
      }

      // Determine deployment status
      if (step === 1 && (uStatus === 4 || uStatus === 5)) {
        this.devShow = 1;
        this.progressBar = 'width:100%'; // 100% after deployment
      } else if (step === 1 && !(uStatus === 4 || uStatus === 5)) {
        this.devShow = 2;
        if (this.paidSetupFee) {
          this.progressBar = 'width:85%';
        }
        if (uStatus === 7) this.devShowText = 'Configuration archived';
        else this.devShowText = 'Validating your configuration';
      } else {
        this.devShow = 3;
      }
    },
    goToConfigSetting() {
      this.$router.push('/configuration')
    },
    goToSetupFee() {
      this.$router.push('/invoice/current')
    },
  },
}
</script>

<style lang="scss" scoped>
.mw-80.step-progress {
  max-width: 85%;

  .progress {
    position: relative;
    top: 39px;
    width: 78%;
    margin: auto;
    z-index: 0;
    height: 5px;
    background-color: #d6cf59;

    .progress-bar {
      background-color: #37b172;
    }
  }

  div.text-center {
    width: 17rem;
  }

  .step-icon-wrapper {
    border: 4px solid #37b172;
    border-radius: 50%;
    color: #37b172;
    padding: 14px;
    display: inline-block;
    background: #a4f8cd;

    .deploy {
      position: relative;
      top: 2px;
      left: -2px;
    }

    &.inactive {
      border-color: #d6cf59;
      background: #fff;
      color: #d6cf59;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 12px;
    color: #37b172;

    &.inactive-text {
      color: #5e5873 !important;
    }
  }

  h5 {
    font-size: 12px;
    margin-top: 0;
    color: #37b172;

    &.inactive-text {
      color: #5e5873 !important;
    }
  }

  .goToConfig:hover {
    color: orange;
    cursor: pointer;
    background-color: #f8e8f1;
  }
}

/* iphone 12/13 pro max - android pro max*/
@media only screen and (max-width: 428px) {

  .mw-80.step-progress {
    max-width: 95%;

    .progress {
      position: relative;
      top: 31px;
    }

    div.text-center {
      width: 8rem;
    }

    .step-icon-wrapper {
      padding: 6px;
    }

    h3 {
      font-size: 13px;
    }

    h5 {
      font-size: 11px;
    }

  }
}

/* small resolution*/
@media only screen and (max-width: 360px) {

  h3 {
    font-size: 11px !important;
  }

  h5 {
    font-size: 10px !important;
  }
}
</style>
