<template>
  <div>
    <!-- <b-row>
      <b-col cols="12">
        <p style="text-align:justify">
          &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; Dear partner, due to our willingness to adjust our usage policy to the current market conditions without
          affecting the service quality and performance, starting on January 1, 2023 we will upgrade the usage limits
          of all our pricing packages as following:
        </p>
      </b-col>
    </b-row> -->
    <b-row align-h="center">
      <b-col cols="5">
        <p class="text-primary">
          <b>Old Packages:</b>
        </p>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="5">
        <b-card
          no-body
        >
          <b-card-body>
            <p class="text-primary text-center">
              <b>{{ userPackage.name }} <span v-if="userPackage.type===2">Casino</span> (€ {{ userPackage.price1 }})</b>
            </p>
            <p class="text-center">
              {{ userPackage.conversions.toLocaleString() }} conversions <b>or</b> {{ (userPackage.clicks) }} clicks
            </p>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="5">
        <p class="text-success">
          <b>New Packages:</b>
        </p>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="5">
        <b-card
          no-body
        >
          <b-card-body>
            <p class="text-success text-center">
              <b>{{ newPackage.name }} <span v-if="newPackage.type===2">Casino</span> (€ {{ newPackage.price1 }})</b>
            </p>
            <p class="text-center">
              {{ newPackage.conversions.toLocaleString() }} conversions <b>+</b> {{ s(newPackage.clicks) }} impressions (clicks) included
            </p>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col
        cols="5"
      >
        <p>
          Your current package is : <br><b>{{ userPackage.name }} €{{ userPackage.price1 }} with <span v-if="userPackage.paymode==='conversions'">{{ userPackage.conversions }} conversions</span> <span v-else>{{ userPackage.clicks }} clicks</span></b>
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <br>
        <b-button
          style="float:right; margin-bottom: 5px;"
          size="sm"
          variant="primary"
          :disabled="upgradeNowBtn"
          @click="upgradeNow"
        >
          <span v-if="upgradeNowBtn">
            <b-spinner
              small
              type="grow"
            />
            UPGRADE TO THE NEW USAGE PLANS NOW
          </span>
          <span v-else>
            <feather-icon
              icon="CheckSquareIcon"
              size="12"
            />
            UPGRADE TO THE NEW USAGE PLANS NOW
          </span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-button
          style="float:right; margin-bottom: 5px;"
          size="sm"
          variant="outline-primary"
          :disabled="upgradeLaterBtn"
          @click="upgradeLater"
        >

          <span v-if="upgradeLaterBtn">
            <b-spinner
              small
              type="grow"
            />
            WAIT FOR 1ST January 2023
          </span>
          <span v-else>
            <feather-icon
              icon="BellIcon"
              size="12"
            />
            WAIT FOR 1ST January 2023
          </span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import axios from '@/libs/axios'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BSpinner,
  },
  props: {
    uDetails: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      userPackage: {
        name: '',
        type: 1,
        conversions: '',
        clicks: '',
        paymode: '',
        paymonths: 0,
        price1: 0,
        price2: 0,
        price3: 0,
      },
      newPackage: {
        name: '',
        type: 1,
        conversions: '',
        clicks: '',
        price1: 0,
        price2: 0,
        price3: 0,
      },
      upgradeNowBtn: false,
      upgradeLaterBtn: false,
      legacy: false,
      newPackageCode: 0,
    }
  },
  mounted() {
    this.getUserPackage()
  },
  methods: {

    getUserPackage() {
      const uri = `/api/account/get_current_package?uid=${this.uDetails.settings.uid}`
      axios.get(uri).then(res => {
        const userPack = res.data[0]
        this.userPackage.name = userPack.name.toUpperCase().trim()
        this.userPackage.clicks = this.numberToString(userPack.clicks)
        this.userPackage.type = userPack.type
        this.userPackage.conversions = userPack.conversions
        this.userPackage.paymode = userPack.paymode
        this.userPackage.paymonths = userPack.pay_months
        this.userPackage.price1 = userPack.price1
        this.userPackage.price2 = userPack.price2
        this.userPackage.price3 = userPack.price3

        const newPack = res.data[1]
        this.newPackage.name = newPack.name.toUpperCase().trim()
        this.newPackage.clicks = this.numberToString(newPack.clicks)
        this.newPackage.type = newPack.type
        this.newPackage.conversions = newPack.conversions
        this.newPackage.price1 = newPack.price1
        this.newPackage.price2 = newPack.price2
        this.newPackage.price3 = newPack.price3
        this.newPackageCode = newPack.uid
      })
    },
    async upgradeNow() {
      this.upgradeNowBtn = true
      const payload = {
        package: this.newPackageCode,
        uid: this.uDetails.settings.uid,
      }
      const uri = 'api/account/upgrade_to_new_package'
      await axios.post(uri, payload).then(res => { // eslint-disable-line
        this.notify({
          text: 'Your current package is upgraded!',
          variant: 'success',
        })
      })
      this.$router.go()
    },
    upgradeLater() {
      this.upgradeLaterBtn = true
      const payload = {
        package: this.newPackageCode,
        uid: this.uDetails.settings.uid,
      }
      const uri = 'api/account/upgrade_later'
      axios.post(uri, payload).then(() => { // eslint-disable-line
        this.$router.go()
      })
    },
    // just an alias
    s(x) {
      return this.numberToString(x)
    },
    numberToString(x) {
      const str = this.suffixNumberString(x)
      let numStr = x.toString() // eslint-disable-line
      if (str === 'million') numStr = `${numStr.slice(0, -6)} ${str}` // eslint-disable-line
      else if (str === 'thousand') numStr = `${numStr.slice(0, -3)} ${str}` // eslint-disable-line
      return numStr
    },
    suffixNumberString(x) { // eslint-disable-line
      if (x >= 1000000 && x <= 999999999) return 'million' // eslint-disable-line
      else if (x >= 1000  && x <= 999999) return 'thousand' // eslint-disable-line
    },
  },
}
</script>
