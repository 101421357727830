<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Visitor Actions
        </b-card-title>
        <b-card-sub-title>Daily Statistics</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        ref="chart"
        type="area"
        height="400"
        :options="chartOptions"
        :series="activities"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import axios from '@/libs/axios' // eslint-disable-line
import moment from 'moment'
import apexChatData from './apexChartData'

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      apexChatData,
      chartOptions: {},
      activities: [],
    }
  },
  mounted() {
    this.getDataChart()
  },
  methods: {

    dateDiffInDays(a, b) {
      const MS_PER_DAY = 1000 * 60 * 60 * 24
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())
      return Math.floor((utc2 - utc1) / MS_PER_DAY)
    },

    formatDate2(date) {
      const datum = new Date(date)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return new Intl.DateTimeFormat('en-US', options).format(datum)
    },

    async getDataChart(objDate = null) {
      let priviousDays = 15
      const priordate = new Date()
      priordate.setDate(priordate.getDate() - priviousDays)
      let start_date = moment(priordate).format('YYYY-MM-DD')
      let end_date = moment(new Date()).format('YYYY-MM-DD')

      if (objDate) {
        start_date = objDate.startDate
        end_date = objDate.endDate

        const date1 = new Date(objDate.startDate)
        const date2 = new Date(objDate.endDate)
        const difference = this.dateDiffInDays(date1, date2)
        priviousDays = difference

        if (difference >= 30) {
          date2.setDate(date2.getDate() - 30)
          start_date = this.formatDate2(date2)
          priviousDays = 30
        }
      }

      const response = await axios.get(`/api/chart/visitors_data?start_date=${start_date}&end_date=${end_date}`)
      if (response.status === 200) {
        this.activities = []
        const { data } = response

        const clicks = []
        const uniqueClicks = []
        const conversions = []
        const categories = []

        const x = priviousDays
        let y = 0

        for (const item of data) { // eslint-disable-line

          clicks.push(Number(item.clicks))
          uniqueClicks.push(Number(item.unique_clicks))
          conversions.push(Number(item.conversions))

          const ldate = new Date(end_date)
          ldate.setDate(ldate.getDate() - (x - y))
          y += 1

          categories.push(moment(ldate).format('Do/MMM'))
        }

        this.chartOptions = this.apexChatData.lineAreaChartSpline.vaChartOption
        this.chartOptions.xaxis.categories = categories

        const clickObj = {
          name: 'Clicks',
          data: clicks,
        }

        const uniqueObj = {
          name: 'Conversions',
          data: uniqueClicks,
        }

        const conversObj = {
          name: 'Registered',
          data: conversions,
        }

        this.activities.push(clickObj)
        this.activities.push(uniqueObj)
        this.activities.push(conversObj)

        this.$refs.chart.updateOptions(this.chartOptions)
        this.$refs.chart.updateSeries(this.activities)
      }

      // window.debugChart = this.$refs.chart
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
