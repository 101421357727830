<template>
  <div
    v-if="limited"
    class="limited-message-container mb-1"
  >
    <div class="limited-message">
      <h2 class="limited-message-title">
        Welcome to your sub-account homepage!</h2>
      <p class="limited-message-text">
        As a sub-account user, you have access by default to tickets, news and product updates, and guides. Your homepage has been customized to show only the information you need, and to help you get the most out of your account.</p>
      <p class="limited-message-text">
        We understand that your time is valuable, and that you don't want to be overwhelmed with information. That's why we've carefully curated the resources and features that we think will be most helpful to you.</p>
      <p class="limited-message-text">
        If you ever have any questions or need assistance, our support team is here to help. Simply open a ticket and we'll get back to you as soon as possible.</p>
      <p class="limited-message-text">
        Additionally, your main account user can give you additional permissions to access more parts of their account. Simply ask them to give you access to the sections you need, or to create a custom limited account for you. This feature is perfect for teams or partners who need access to specific information without being able to modify critical data.</p>
      <p class="limited-message-text">
        Thank you for being a valued member of our community, and we look forward to continuing to serve you in the future.</p>
    </div>
  </div>
  <div
    v-else
    class="home-wrapper"
  >
    <b-row>
      <b-col sm="12">
        <setup-progress-bar
          :show-detail="initShowDetail"
          :user_status="user_status"
          :settings="settings"
          :info="userDetail"
          :paid-setup-fee="paidSetupFee"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <date-range-picker
          ref="picker"
          v-model="statDates"
          class="stat-picker"
          style="width: auto;margin-bottom: 15px;"
          opens="right"
          :ranges="ranges"
          @update="getNewStats"
        >
          <div
            slot="input"
            slot-scope="picker"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
              class="stat-calendar"
            />
            {{ picker.startDate | date }} - {{ picker.endDate | date }}
            <span v-if="getNewStatsSpinner">
              <b-spinner
                small
                type="grow"
              />
            </span>
          </div>
          <div
            slot="footer"
            slot-scope="data"
            class="slot"
          >
            <div class="custom-search d-flex justify-content-end">
              <!-- <span>Date Range:</span> <span>{{ data.rangeText }}</span> -->
              <b-button
                size="sm"
                variant="outline-primary"
                @click="data.clickCancel"
              >
                Cancel
              </b-button>&nbsp;
              <b-button
                size="sm"
                variant="primary"
                @click="data.clickApply"
              >
                Apply
              </b-button>
            </div>
          </div>
        </date-range-picker>
      </b-col>
      <b-col>
        <div class="mb-2 mt-1">
          ( Statistics are updating every hour )
        </div>
      </b-col>
    </b-row>
    <b-row v-if="isNewPackage===false">
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-custom
          :show-detail="showDetail"
          icon="PieChartIcon"
          :statistic="formatNumber(conversionClick)"
          :statistic-title="conversionClickText"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-custom
          :show-detail="showDetail"
          icon="CheckCircleIcon"
          :statistic="formatNumber(conversions)"
          statistic-title="Conversions Used"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-custom
          :show-detail="showDetail"
          icon="MousePointerIcon"
          color="info"
          :statistic="formatNumber3(unique_clicks)"
          statistic-title="Unique Clicks"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-custom
          :show-detail="showDetail"
          icon="MousePointerIcon"
          color="success"
          :statistic="formatNumber(clicks)"
          statistic-title="Clicks"
        />
      </b-col>
    </b-row>
    <b-row v-if="isNewPackage===false">
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-custom
          :show-detail="showDetail"
          icon="EyeIcon"
          color="warning"
          :statistic="formatNumber(impressions)"
          statistic-title="Impressions"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-custom
          :show-detail="showDetail"
          icon="TrendingUpIcon"
          :statistic="formatNumber(payout)"
          statistic-title="Payout Revenue"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-custom
          :show-detail="showDetail"
          icon="RepeatIcon"
          :statistic="formatNumber(conversions_amount)"
          statistic-title="Conversional Val"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-custom
          :show-detail="showDetail"
          icon="CreditCardIcon"
          color="success"
          :statistic="formatNumber(profit)"
          statistic-title="Gross Profit"
        />
      </b-col>
    </b-row>
    <b-row v-if="isNewPackage">
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-custom
          class="auto-resize"
          :show-detail="showDetail"
          icon="PieChartIcon"
          :statistic="formatNumber3(clicks)"
          :statistic-sub="clicks>0?'Used Clicks':'Used Click'"
          :statistic-title="clickNewPackageText"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-custom
          class="auto-resize"
          :show-detail="showDetail"
          icon="CheckCircleIcon"
          :statistic="formatNumber3(conversions)"
          :statistic-sub="conversions>0?'Used Conversions':'Used Conversion'"
          :statistic-title="conversionNewPackageText"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-custom
          class="auto-resize"
          :show-detail="showDetail"
          icon="CreditCardIcon"
          color="success"
          :statistic="'€ ' + formatNumber(total_click_overage_cost)"
          :statistic-sub="''"
          statistic-title="Total Overage Cost"
          :total-overage="'Total Overage Clicks: ' + total_click_overage"
          :total-overage-cost="'Overage Clicks Price: €' + overageClicks"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-custom
          class="auto-resize"
          :show-detail="showDetail"
          icon="CreditCardIcon"
          color="info"
          :statistic="'€ ' + formatNumber(total_conversion_overage_cost)"
          :statistic-sub="''"
          statistic-title="Total Overage Cost"
          :total-overage="'Total Overage Conversions: ' + total_conversion_overage"
          :total-overage-cost="'Overage Conversion Price: €' + formatNumber2(overageConversion)"
        />
      </b-col>
    </b-row>
    <b-row v-if="isNewPackage">
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-custom
          :show-detail="showDetail"
          icon="MousePointerIcon"
          color="info"
          :statistic="formatNumber3(unique_clicks)"
          :statistic-sub="''"
          statistic-title="Unique Clicks"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-custom
          :show-detail="showDetail"
          icon="TrendingUpIcon"
          :statistic="formatNumber(payout)"
          :statistic-sub="''"
          statistic-title="Payout Revenue"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-custom
          :show-detail="showDetail"
          icon="RepeatIcon"
          :statistic="formatNumber(conversions_amount)"
          :statistic-sub="''"
          statistic-title="Conversional Val"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-custom
          :show-detail="showDetail"
          icon="CreditCardIcon"
          color="success"
          :statistic="formatNumber(profit)"
          :statistic-sub="''"
          statistic-title="Gross Profit"
        />
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center">
      <b-col
        sm="6"
      >
        <visitor-actions ref="visitor" />
      </b-col>
      <b-col
        sm="6"
      >
        <financial ref="financial" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
/* eslint-disable */
import {
  BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import DateRangePicker from 'vue2-daterange-picker'
import VisitorActions from './chart/VisitorActions.vue'
import Financial from './chart/Financial.vue'
import SetupProgressBar from './SetupProgressBar.vue'
import StaticCardVue from './StaticCard.vue'

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    BRow,
    BCol,
    VisitorActions,
    Financial,
    SetupProgressBar,
    'statistic-custom': StaticCardVue,
    DateRangePicker,
    BButton,
    BSpinner,
  },
  filters: {
    date(date) {
      const datum = new Date(date)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return new Intl.DateTimeFormat('en-US', options).format(datum)
    },
  },
  data() {
    return {
      conversionClick: 0,
      conversionClickText: '',
      showDetail: false,
      initShowDetail: false,
      settings: {},
      user_status: 1,
      conversions: 0,
      unique_clicks: 0,
      clicks: 0,
      impressions: 0,
      payout: 0,
      conversions_amount: 0,
      profit: 0,
      userDetail: {},
      isNewPackage: false,
      clickNewPackageText: '',
      conversionNewPackageText: '',
      overageClicks: 0.0,
      overageConversion: 0.0,

      total_click_overage: 0.0,
      total_click_overage_cost: 0.0,

      total_conversion_overage: 0.0,
      total_conversion_overage_cost: 0.0,

      statDates: {
        startDate: null,
        endDate: null,
      },
      ranges: {},
      getNewStatsSpinner: false,
      paidSetupFee: false,
    }
  },
  computed: {
    userData() {
      return this.$store.state.home.userData
    },
    userDataAuth() {
      return this.$store.state.auth.userData
    },
    limited() {
      return this.$store.state.home.limited
    }
  },
  created() {
    this.fetchUser()
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    // initial date -statistics
    const today = new Date()
    const initStartDate = new Date(today.getFullYear(), today.getMonth(), 1)
    const initEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    this.statDates.startDate = this.formatDate2(initStartDate)
    this.statDates.endDate = this.formatDate2(initEndDate)

    this.showDetail = false
    this.initShowDetail = false
    const refreshId = setInterval(() => {
      if (this.userData) {
        this.showDetail = true
        this.initShowDetail = true
        this.initData(this.userData)
        clearInterval(refreshId)
        const excluded_users_overage = [1894472889, 8289506367, 4601411931, 4349234747, 2964123870]
        if (excluded_users_overage.includes(this.userData.settings.uid)) {
          this.total_click_overage_cost = 0
          this.total_conversion_overage_cost = 0
        }
        this.paidSetupFee = this.userData.setup_fee_paid
      }
    }, 500)
    this.refreshNotifyLead()
  },
  methods: {
    refreshNotifyLead() {
      setInterval(async () => {
        // if the user is lead notify him to complete his account by going to my account tab
        if (!this.userData.billing.bill_country && !this.userDataAuth.isAdmin) {
          await this.$bvToast.toast('Please complete your account to get full access to the dashboard. Please go in My Account tab under your profile.', {
            title: 'Account Incomplete',
            variant: 'warning',
            solid: true,
          })
        }
      }, 15000)
    },
    handleLogout() {
      // Log out the user
      this.$store.dispatch('auth/logout')
      this.$router.push('/')
    },
    async getNewStats() {
      this.getNewStatsSpinner = true
      this.showDetail = false
      const obj = {
        startDate: this.formatDate2(this.statDates.startDate),
        endDate: this.formatDate2(this.statDates.endDate),
      }
      await this.$store.dispatch('home/resetUser', obj)

      const info = this.userData
      this.settings = info.settings
      this.user_status = info.user_status
      this.conversions = info.conversions
      this.unique_clicks = info.unique_clicks
      this.clicks = info.clicks
      this.impressions = info.impressions
      this.payout = info.payout
      this.conversions_amount = info.conversions_amount
      this.profit = info.profit

      this.getNewStatsSpinner = false
      this.showDetail = true

      this.overageClicks = info.package.overage_clicks
      this.overageConversion = info.package.overage_conv

      this.total_click_overage = this.clicks - info.package.clicks
      if (this.total_click_overage < 0) this.total_click_overage = 0
      this.total_click_overage_cost = this.total_click_overage * this.overageClicks

      this.total_conversion_overage = this.conversions - info.package.conversions
      if (this.total_conversion_overage < 0) this.total_conversion_overage = 0
      this.total_conversion_overage_cost = this.total_conversion_overage * this.overageConversion

      this.$refs.financial.getDataChart(obj)
      this.$refs.visitor.getDataChart(obj)
      this.$forceUpdate()
    },
    fetchUser() {
      this.$store.dispatch('home/fetchUser')
    },
    formatNumber(num) {
      const numfinal = Number(num)
      return numfinal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },
    formatNumber2(num) {
      const numfinal = Number(num)
      return numfinal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 6,
      })
    },
    formatNumber3(num) {
      const numfinal = Number(num)
      return numfinal.toLocaleString(undefined, {
        maximumFractionDigits: 6,
      })
    },
    initData(info) {
      // const { account_type } = info
      // const { var_account_type } = info
      const packageUID = Number(info.package.uid)

      this.userDetail = info

      const { billing } = info
      const packInfo = info.package
      if (billing.paymode == 'clicks') { // eslint-disable-line
        this.conversionClick = packInfo.clicks
        this.conversionClickText = 'Available Clicks'
      } else {
        this.conversionClick = packInfo.conversions
        if (packageUID === 5003 || packageUID === 6003) this.conversionClickText = 'Signups and Dep.' // eslint-disable-line
        else this.conversionClickText = 'Conversions'
      }

      this.settings = info.settings
      this.user_status = info.user_status
      this.conversions = info.conversions
      this.unique_clicks = info.unique_clicks
      this.clicks = info.clicks
      this.impressions = info.impressions
      this.payout = info.payout
      this.conversions_amount = info.conversions_amount
      this.profit = info.profit

      if ((packageUID >= 7003 && packageUID <= 7006) || packageUID === 5003 || packageUID === 6003) this.isNewPackage = true

      this.clickNewPackageText = `Included Clicks: ${this.numberToString(info.package.clicks)}`
      // this.conversionNewPackageText = `Included: ${this.numberToString(info.package.conversions)}`
      if (packageUID === 5003 || packageUID === 6003) this.conversionNewPackageText = `Signups and Deposits: ${info.package.conversions} (included)`
      else this.conversionNewPackageText = `Included Conversions: ${info.package.conversions}`

      this.overageClicks = info.package.overage_clicks
      this.overageConversion = info.package.overage_conv

      this.total_click_overage = this.clicks - info.package.clicks
      if (this.total_click_overage < 0) this.total_click_overage = 0
      this.total_click_overage_cost = this.total_click_overage * this.overageClicks

      this.total_conversion_overage = this.conversions - info.package.conversions
      if (this.total_conversion_overage < 0) this.total_conversion_overage = 0
      this.total_conversion_overage_cost = this.total_conversion_overage * this.overageConversion

      this.statDates.startDate = this.formatDate2(info.startDate.date)
      this.statDates.endDate = this.formatDate2(info.endDate.date)
      this.dataPickerRanges()
    },

    formatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },

    formatDate2(date) {
      const datum = new Date(date)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return new Intl.DateTimeFormat('en-US', options).format(datum)
    },

    dataPickerRanges() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      const yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0)

      const thisMonthStart = new Date(this.statDates.startDate)
      const thisMonthEnd = new Date(this.statDates.endDate)

      const last7days = new Date()
      last7days.setDate(today.getDate() - 7)
      last7days.setHours(0, 0, 0, 0)

      const ranges = {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        'Last 7 days': [last7days, today],
        'This month': [thisMonthStart, thisMonthEnd],
        'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
      }
      this.ranges = ranges
    },

    numberToString(x) {
      const str = this.suffixNumberString(x)
      let numStr = x.toString() // eslint-disable-line
      if (str === 'million') numStr = `${numStr.slice(0, -6)} ${str}` // eslint-disable-line
      else if (str === 'thousand') numStr = `${numStr.slice(0, -3)} ${str}` // eslint-disable-line
      return numStr
    },
    suffixNumberString(x) { // eslint-disable-line
      x = Number(x) // eslint-disable-line
      if (x >= 1000000 && x <= 999999999) return 'million' // eslint-disable-line
      else if (x >= 1000  && x <= 999999) return 'thousand' // eslint-disable-line
    },

  },

}
</script>

<style>
.home-wrapper div.col-sm-6.col-lg-3 a{
  color: #6e6b7b;
}
.activation-btn {
  width: 100%;
  max-width: 16rem;
}
.slot {
  padding: 0.5rem;
  justify-content: space-between;
}
.text-primary{
  margin-bottom: 5px;
  margin-top: 5px;
}
.slot span{
  margin-right:5px; margin-top:5px;
  font-size: 11px;
  font-weight: bold;
}

.auto-resize {
  height: calc(300px - 11vw) !important;
  min-height: 130px !important;
}

@media screen and (max-width: 1280px) {
  .auto-resize {
    height: calc(300px - 9vw) !important;
  }
}

@media screen and (max-width: 1200px) {
  .auto-resize {
    height: calc(300px - 10vw) !important;
  }
}

@media screen and (max-width: 992px) {
  .auto-resize {
    height: 150px !important;
  }
}

@media screen and (max-width: 492px) {
  .auto-resize {
    height: 120px !important;
  }
}
</style>

<style>
.reportrange-text[data-v-1ebd09d2] {
  height: fit-content !important;
  white-space: pre-line;
  width: 24rem;
}
.limited-message {
  opacity: 0.8;
  padding: 20px;
  box-shadow: 0px 0px 25px rgba(194, 63, 141, 0.5);
  border-radius: 5px;
}
.limited-message-title {
  margin-top: 0;
  font-size: 2em;
  color: #c23f8d !important;
  text-shadow: 2px 1px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  margin-bottom: 25px;
}

.limited-message-text {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 10px;
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  text-shadow: 0px 1px 0 rgba(0, 0, 0, 1);
  margin-bottom: 25px;
}

.limited-message-text:last-child {
  margin-bottom: 5px;
}

@keyframes limited-message-border {
  0% {
    border: 5px solid #c23f8d;
  }
  50% {
    border: 5px solid #f0c2e8;
  }
  100% {
    border: 5px solid #c23f8d;
  }
}
.limited-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
