<template>
  <div v-if="arrayInvoices">
    <b-badge
      v-if="arrayInvoices.length > 0"
      variant="light-danger"
    >
      Last 60 days
    </b-badge>
    <div
      v-for="(inv, index) in arrayInvoices.slice(0, 5)"
      :id="`invoice_id_${index}`"
      :key="inv.id"
      class="position-relative invoices-wrapper"
      :class="index !== 0 && 'mt-1'"
    >
      <div class="invoice-item">
        <div
          class="item-close"
          @click="hideInvoice(index, inv)"
        >
          <feather-icon
            icon="XIcon"
            size="16"
            class="align-text-top"
          />
        </div>
        <p class="m-0">
          <strong class="mr-1 text-dark"><feather-icon
            icon="CalendarIcon"
            size="16"
            class="align-text-top"
          /> {{ formatDate(inv.issue_date) }}</strong>
          <span v-if="inv.is_credit_note">Pro Forma Invoice# {{ inv.credit_note }}</span>
          <span v-else>Invoice# {{ inv.invoice }}</span>
        </p>
        <h4 class="d-flex mb-1 mt-1 align-items-center">
          <router-link
            v-b-tooltip.hover.left.v-success
            :to="`/user/details/${inv.uid}`"
            title="Go to User"
            class="mr-1"
          >
            <feather-icon
              icon="UserIcon"
              size="27"
              class="text-success"
            />
          </router-link>
          <span
            v-b-tooltip.hover.top.v-primary
            style="cursor: pointer;"
            class="text-primary"
            title="Go to Invoice"
            @click="goToDetail(inv.id)"
          >
            {{ inv.invoice_rows[0].item ? inv.invoice_rows[0].item : 'Custom' }}
          </span>
        </h4>
        <p class="m-0">
          <span
            class="font-weight-bold"
            style="margin-right: 2px;"
          >{{ inv.is_credit_note ? 'Pro Forma Invoice value:' : 'Invoice value:' }}</span>
          <span> {{ `${inv.currency.toUpperCase()} ${parseFloat(inv.invoice_value).toFixed(2)} (inc.VAT ${inv.vat}%)` }}</span>
        </p>
        <p class="m-0">
          <span
            class="font-weight-bold"
            style="margin-right: 2px;"
          >Period:</span> {{ `${formatDate(inv.start_date)} - ${formatDate(inv.end_date)}` }}
        </p>
        <p class="m-0">
          <span
            class="font-weight-bold"
            style="margin-right: 2px;"
          >Domain:</span> {{ checkDomainSubdomain(inv.setting_domain, inv.setting_sub_admin) }}
        </p>
      </div>
    </div>

    <p class="mt-2 text-right font-weight-bold">
      <router-link to="/billing/overdue-forma-invoices">
        See all ({{ adminData.cinvoices }})
      </router-link>
      <br>
      All Period
    </p>

  </div>
</template>

<script>
import {
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      arrayInvoices: [],
    }
  },
  computed: {
    adminData() {
      return this.$store.state.home.adminData
    },
  },
  mounted() {
    this.filteredInvoices()
  },
  methods: {
    checkDomainSubdomain(domain, subdomain) {
      const checkDefaultDomain = dns => {
        const defaultDomains = [
          'admin.trafficmanager.com',
          'dashboard.trafficmanager.com',
          'default.trafficmanager.com',
          'smart.trafficmanager.com',
          'banners.trafficmanager.com',
          'postback.trafficmanager.com',
          'offers.trafficmanager.com',
        ]
        return defaultDomains.includes(dns)
      }

      let domainText = null
      if (domain) domainText = domain
      else if (subdomain) {
        domainText = `${subdomain}.trafficmanager.com`
        if (checkDefaultDomain(domainText)) domainText = 'N/A'
      } else if (!domain && !subdomain) domainText = 'N/A'
      return domainText
    },
    filteredInvoices() {
      this.arrayInvoices = this.adminData.invoices.filter(e => {
        const now = moment(new Date())
        const date = moment(e.created_at)
        const diff = now.diff(date, 'days')
        return diff <= 60 && e.visible === 1 && e.invoice_status === 'overdue'
      })
    },
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    goToDetail(id) {
      this.$router.push(`/invoices/${id}`)
    },
    hideInvoice(index, { id, credit_note }) {
      this.confirmationAlert('Are you sure you want to hide this pro forma invoice ?').then(res => {
        if (res.isConfirmed) {
          const el = document.getElementById(`invoice_id_${index}`)
          el.classList.add('fadeout')
          this.$store.dispatch('home/hideInvoice', { id, credit_note, type: 'invoice' })
          setTimeout(() => {
            this.arrayInvoices.splice(index, 1)
            el.remove()
          }, 600)
        }
      })
    },
    // deleteInvoice(i) {
    //   const inv = this.adminData.invoices[i]
    //   const invNo = inv.is_credit_note ? inv.credit_note : inv.invoice
    //   const invDomain = inv.setting.network_type === 'domain' ? `${inv.setting.sub_admin}.${inv.setting.domain}` : `${inv.setting.subdomain}.trafficmanager.com`

    //   this.confirmationAlert(`Are you sure you want to delete the pro forma invoice #${invNo} for domain ${invDomain} ?`)
    //     .then(res => {
    //       if (res.isConfirmed) {
    //         this.$store.dispatch('home/deleteInvoice', inv.credit_note).then(() => {
    //           this.$store.commit('home/REMOVE_INVOICE', i)
    //           this.notify({
    //             text: 'Deleted Successfully!',
    //             variant: 'success',
    //           })
    //         })
    //       }
    //     })
    // },
  },
}
</script>

<style lang="scss" scoped>
.fadeout {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.invoices-wrapper {
  border: 1px groove #c7c7c7;
  border-left: 4px solid #ea5455;
  padding: 15px 26px;
  border-radius: 5px;
  .invoice-item {
    .item-close {
      background: #ea5455;
      border-left: 1px solid #c7c7c7;
      border-bottom: 1px solid #c7c7c7;
      border-radius: 0px 5px 0px 5px;
      position: absolute;
      right: 0;
      top: 0;
      padding: 5px;
      color: #ffffff;
      font-size: 13px;
      cursor: pointer;
      transition: .5s;

      &:hover {
        background: #b30d46;
      }
    }
  }
}
</style>
